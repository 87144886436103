import router from "../router";
import {
  DISTRICT,
  SCHOOL,
  TEACHER,
  PE_TEACHER,
  PRINCIPAL,
} from "@/constants/ModuleKeys";
import { ROUTER_URL } from "@/constants/urls";
var CryptoJS = require("crypto-js");

const Helper = {
  formatDate: (date) => {
    if (!date) return null;

    date = new Date(date);

    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var monthDate = "";

    if (parseInt(date.getDate()) < 10) {
      monthDate = "0" + date.getDate();
    } else {
      monthDate = date.getDate();
    }

    const nth = function (d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    return (
      monthDate +
      nth(monthDate) +
      " " +
      monthNames[date.getMonth()] +
      " " +
      date.getFullYear()
    );
  },
  getDay(dateString) {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date(dateString);
    return days[d.getDay()];
  },
  // Format time as hh:mm ap
  formatTime(d) {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    var ap = d.getHours() < 12 ? "AM" : "PM";
    return z(d.getHours()) + ":" + z(d.getMinutes()) + " " + ap;
  },
  formatTimes(timeString) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
  },
  convertFrom24To12Format(time24) {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? "AM" : "PM";
    const hours = +sHours % 12 || 12;

    return `${"0" + hours}:${minutes} ${period}`;
  },
  convertTime12To24(time) {
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === "PM" && hours < 12) hours = hours + 12;
    if (AMPM === "AM" && hours === 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
  },
  getTimezoneName(date, shortHand = false) {
    const short = date.toLocaleDateString(undefined);
    const full = date.toLocaleDateString(undefined, { timeZoneName: "long" });

    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed =
        full.substring(0, shortIndex) +
        full.substring(shortIndex + short.length);
      // by this time `trimmed` should be the timezone's name with some punctuation -
      // trim it from both sides
      return shortHand
        ? trimmed
            .replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "")
            .match(/\b\w/g)
            .join("")
            .toUpperCase()
        : trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "");
    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return shortHand ? full.match(/\b\w/g).join("").toUpperCase() : full;
    }
  },
  hasHistory() {
    return window.history.length > 1;
  },
  goBack() {
    Helper.hasHistory() ? router.go(-1) : router.push("/");
  },
  isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  },
  encryptData(data) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(data),
      "7c606d287b6d6b7a6d7c287b7c7a61666f"
    ).toString();
  },
  decryptData(token) {
    return CryptoJS.AES.decrypt(
      token,
      "7c606d287b6d6b7a6d7c287b7c7a61666f"
    ).toString(CryptoJS.enc.Utf8);
  },
  getDateMonth(dateString, shortHand = false) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthShortNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var d = new Date(dateString);
    return shortHand ? monthShortNames[d.getMonth()] : monthNames[d.getMonth()];
  },
  getOnlyDate(dateString) {
    var d = new Date(dateString);
    return d.getDate();
  },
  formatDashedDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  },

  getUserPanelUrl(userTeam) {
    switch (userTeam) {
      case DISTRICT:
        return (
          ROUTER_URL.districtPanel.link +
          ROUTER_URL.districtPanel.children.dashboard.link
        );
      case SCHOOL:
        // return ROUTER_URL.schoolPanel.link + ROUTER_URL.schoolPanel.children.dashboard.link
        return ROUTER_URL.schoolLandingPage.link;
      case TEACHER:
        return (
          ROUTER_URL.teacherPanel.link +
          ROUTER_URL.teacherPanel.children.dashboard.link
        );
      case PE_TEACHER:
        return (
          ROUTER_URL.peteacherPanel.link +
          ROUTER_URL.peteacherPanel.children.dashboard.link
        );
      case PRINCIPAL:
        return (
          ROUTER_URL.principalPanel.link +
          ROUTER_URL.principalPanel.children.dashboard.link
        );
      // case GAME:
      //   return ROUTER_URL.gamePanel.link + ROUTER_URL.gamePanel.children.gamehostassembly.link
      default:
        return (
          ROUTER_URL.adminPanel.link +
          ROUTER_URL.adminPanel.children.dashboard.link
        );
    }
  },
  getUserPanel(userTeam) {
    switch (userTeam) {
      case DISTRICT:
        return ROUTER_URL.districtPanel.name;
      case SCHOOL:
        return ROUTER_URL.schoolPanel.name;
      // return ROUTER_URL.schoolLandingPage.name
      case TEACHER:
        return ROUTER_URL.teacherPanel.name;
      case "pe-teacher":
        return ROUTER_URL.peteacherPanel.name;
      case PRINCIPAL:
        return ROUTER_URL.principalPanel.name;
      //  case GAME:
      //   return ROUTER_URL.gamePanel.name
      default:
        return ROUTER_URL.adminPanel.name;
    }
  },
  openPublicDonationPage(subDomain, queryParam) {
    // queryParam = queryParam ? '?' + queryParam : ''
    queryParam = queryParam ? "/parent?" + queryParam : "";

    var hosts = subDomain.replace(" ,", "-");
    // var hostss =  hosts.replace(",","-")
    console.log("***n", hosts);
    var subDomains = hosts ? hosts + "." : "";
    subDomain = subDomains.replace(", ", "-");
    console.log("***n", subDomain.replace(", ", "-"));
    const host = window.location.host;
    const protocol = window.location.protocol;

    const href = protocol + "//" + subDomain + host + queryParam;
    window.open(href);
  },
  redirectToPublicDonationPage(subDomain, queryParam) {
    // queryParam = queryParam ? '?' + queryParam : ''
    queryParam = queryParam ? "?" + queryParam : "";
    subDomain = subDomain ? subDomain + "." : "";
    const host = window.location.host;
    const protocol = window.location.protocol;
    const href = protocol + "//" + subDomain + host + queryParam;
    window.open(href);
  },
  openPublicDonationPageHref(subDomain, queryParam) {
    queryParam = queryParam ? "?" + queryParam : "";
    subDomain = subDomain ? subDomain + "." : "";
    const host = window.location.host;
    const protocol = window.location.protocol;
    const href = protocol + "//" + subDomain + host + queryParam;
    return href;
  },
  timeFormatter(time) {
    // console.log("formate calling");
    let timeInt = parseInt(time);
    let minutes = time.substring(3, 5);
    if (time.split(":")[0] == "00") return `12:${time.split(":")[1]} AM`;
    if (time >= "13:00") return `${timeInt - 12}:${minutes} PM`;
    else if (time >= "12:00") return `${timeInt}:${minutes} PM`;
    else return `${timeInt}:${minutes} AM`;
  },
};

export default Helper;
